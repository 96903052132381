<template>
  <section class="relative">

      <!-- Blurred shape -->
      <div class="absolute top-0 -mt-24 left-0 xl:left-16 -ml-16 blur-2xl opacity-70 pointer-events-none -z-10" aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
              <defs>
                  <linearGradient id="bs4-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                      <stop offset="0%" stop-color="#00966A" />
                      <stop offset="100%" stop-color="#00966A" stop-opacity="0" />
                  </linearGradient>
              </defs>
              <path fill="url(#bs4-a)" fill-rule="evenodd" d="m0 0 461 369-284 58z" transform="matrix(1 0 0 -1 0 427)" />
          </svg>
      </div>

    <div class="max-w-7xl mx-auto px-6 sm:px-8">
      <div class="pt-16 pb-12 md:pt-24 md:pb-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2 bg-clip-text text-transparent bg-gradient-to-r from-zinc-200/60 via-zinc-200 to-zinc-200/60 pb-4">Not just a data transporter.</h2>
          <p class="text-lg text-zinc-400">Synci enriches your data with clear merchant names, so you don't have to assign payees once transactions have been transferred.</p>
        </div>

        <div class=" mx-auto">
          <div data-aos="fade-down">
              <div class="relative mx-auto px-1 pt-2 overflow-hidden">
                  <img draggable="false" v-lazy="'/assets/screenshots/transactions-dark.webp'" alt="Screenshot of transactions" class="mb-[-12%] block  z-20 rounded-3xl ring-1 ring-white/10" width="3024" height="1786" />
                  <div class="relative" aria-hidden="true">
                      <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-zinc-950 pt-[7%]" />
                  </div>
              </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Highlighter from './Highlighter.vue'
import HighlighterItem from './HighlighterItem.vue'

export default {
  name: 'Features03',
  components: {
    Highlighter,
    HighlighterItem,
  },
}
</script>
